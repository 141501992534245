import { FC, useEffect } from "react"
import config from "../../config.json"

import { SentryErrorBoundary } from "../shared-components/Shared/ErrorBoundary"
import { BaseHead, Base } from "../shared-components/Base/Base"

export const Head: FC = () => {
  return (
    <SentryErrorBoundary>
      <BaseHead
        metaTitle="Sign out"
        metaDescription=""
        canonicalUrl="https://www.habito.com/sign-out"
        noIndex={true}
        pageName="Sign out"
        intercom={false}
        config={config}
      />
    </SentryErrorBoundary>
  )
}

const SignOut: FC = () => {
  useEffect(() => window.location.replace(config.authURL + "/logout"))

  /**
   * We necessarily don't want to use WrappedBase here.
   * WrappedBase deals with either an already authenticated user or a user who needs a token.
   * Either way that isn't relevant for the set-token page, because we're actually in the process
   * of authenticating.
   * It would also be dangerous, as the user provider can overwrite the token we're trying to set.
   **/
  return (
    <SentryErrorBoundary>
      <Base />
    </SentryErrorBoundary>
  )
}

export default SignOut
